import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "Top" }
const _hoisted_2 = { class: "Meta" }
const _hoisted_3 = {
  key: 0,
  class: "MetaRow Logo"
}
const _hoisted_4 = {
  key: 1,
  class: "MetaRow"
}
const _hoisted_5 = {
  key: 2,
  class: "MetaRow"
}
const _hoisted_6 = { class: "MetaRow" }
const _hoisted_7 = { class: "MetaRow" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "MetaRow" }
const _hoisted_10 = { class: "Header" }
const _hoisted_11 = { class: "UnitInfo" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "CreatedTimeStamp" }
const _hoisted_14 = { class: "QRBox" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "QR" }
const _hoisted_17 = { class: "MilvaLogo" }
const _hoisted_18 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_PDFPage = _resolveComponent("PDFPage")!

  return (_ctx.project)
    ? (_openBlock(), _createBlock(_component_PDFPage, {
        key: 0,
        class: "PDFFrontpage",
        context: _ctx.context
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Image, {
              class: "ProfileImage",
              context: _ctx.context,
              image: _ctx.reportImage,
              loading: "eager",
              dimensions: {
          height: 360,
          width: 360,
        },
              transformation: 'square_400'
            }, null, 8, ["context", "image"]),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.account?.logo)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Image, {
                      class: "AccountLogo",
                      context: _ctx.context,
                      path: _ctx.account.logo,
                      loading: "eager",
                      transformation: 'overview_account_logo'
                    }, null, 8, ["context", "path"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.states.isBelfor)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.mixWB('REPORTING_DATE')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.mixGetDate(_ctx.project.data.screeningReportDeliveredDate) || '-'), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.project.data.screeningRevisedDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.mixWB('DATE_OF_REVISION')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.mixGetDate(_ctx.project.data.screeningRevisedDate)), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.mixWB('REQUESTER')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('BUILDER')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.project.getProjectDoneByText()), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.account?.name), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.mapper?.fullName), 1),
                (!_ctx.states.isBelfor)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.mixWB('PHONE')) + ": " + _toDisplayString(_ctx.mapper?.phoneNumber), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.mixWB('CASE_NUMBER')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.project.getCaseNumber()), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.projectAddress), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.ownerAssociation)
              ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.mixWB('LAND_OWNER_ASSCOSIATION')), 1),
                  _createTextVNode(": " + _toDisplayString(_ctx.ownerAssociation), 1)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildings, (building) => {
              return (_openBlock(), _createElementBlock("p", {
                key: building.hashID
              }, [
                _createElementVNode("strong", null, _toDisplayString(building.getTitle()), 1),
                _createTextVNode(": " + _toDisplayString(_ctx.buildingMeta(building)), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('DOCUMENT_CREATED')) + ": " + _toDisplayString(_ctx.mixGetDate(_ctx.created, 'DATE_FORMAT_2')), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.mixWB('MILVA_ONLINE_REPORT')), 1),
              _createElementVNode("p", { innerHTML: _ctx.qrBoxText }, null, 8, _hoisted_15)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_InlineSVG, {
                  src: require('@/assets/logo/milva-icon.svg')
                }, null, 8, ["src"])
              ]),
              _createElementVNode("img", {
                ref: "image",
                src: _ctx.getPath(),
                onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onQRImageLoaded && _ctx.onQRImageLoaded(...args)))
              }, null, 40, _hoisted_18)
            ])
          ])
        ]),
        _: 1
      }, 8, ["context"]))
    : _createCommentVNode("", true)
}