import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-262b6f68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PDFPreview CustomScrollbar" }
const _hoisted_2 = { class: "ButtonContainer" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "Info"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  ref: "Content",
  class: "ReportWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["PDFStatus", {IsReady: _ctx.pdfReady}])
      }, [
        (_ctx.pdfReady)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.mixWB('STATUS')) + ": " + _toDisplayString(_ctx.mixWB('READY').toLowerCase()), 1))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('STATUS')) + ": " + _toDisplayString(_ctx.mixWB('IS_BEING_BUILT').toLowerCase()), 1),
              _createVNode(_component_Spinner, { size: 'auto' })
            ], 64))
      ], 2),
      (_ctx.showDownloadButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["Button Download", {
          IsActive: _ctx.pdf.requested,
          IsDisabled: !_ctx.pdfReady || _ctx.pdf.requested
        }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickPDFDownload()))
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('DOWNLOAD_REPORT')), 1),
              (_ctx.pdf.requested)
                ? (_openBlock(), _createBlock(_component_Spinner, {
                    key: 0,
                    size: 'auto'
                  }))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.isScreening)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.mixWB('Rapport ikke tilgængelig i screening projekter.')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.mixWB('Rapport kun tilgængelig i screening projekter.')), 1))
          ]))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "default", {
        created: _ctx.created,
        addAsPending: _ctx.addAsPending,
        removeAsPending: _ctx.removeAsPending
      }, undefined, true)
    ], 512)
  ]))
}